import React, {useState} from 'react'
import {logFormSubmission} from "../../../utils/google-tag-manager";


const ShortForm = props => {
    const {
        shortForm
    } = props
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [company, setCompany] = useState("");
    const [phone, setPhone] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(undefined);


    const handleSubmit = async (evt) => {

        evt.preventDefault();
        const data = {
            "fields": [
                {
                    "name": "email",
                    "value": email
                },
                {
                    "name": "firstname",
                    "value": name
                },
                {
                    "name": "company",
                    "value": company
                },
                {
                    "name": "phone",
                    "value": phone
                }
            ],
            "context": {
                // "pageUri": "http://localhost:8000",
                // "PageName": "Product Page Australia",
                // "skipValidation": true
            },
        };

        const response = await fetch(shortForm?.hubspotLink, {
            body: JSON.stringify(data),
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (response.status === 200) {
            setSubmitted(true);
            logFormSubmission('landing-pages');
            setError(undefined)
        } else if (response.status === 400) {
            setError(`${shortForm?.emailAlert}`)
        }
    }

    return (
        <section className="">
            <div className="container">
                <div className='row'>
                    <div className="col-12">
                    <h3 className="display-7 text-white pb-4">{shortForm?.title}</h3>
                    {submitted ? <h4 className='px-5 success-message-form-black'>{shortForm?.onSubmittedMessage}</h4> :
                    
                        <form onSubmit={handleSubmit}>
                            <div className="row ">
                                <div className="col-12 col-lg-6 mx-auto pe-md-0">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control-lg cashflow-form-input"
                                            id="your_name"
                                            placeholder={`${shortForm?.nameLabel} *`}
                                            value={name}
                                            required
                                            onChange={e => setName(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="col-12 col-lg-6 mx-auto pe-md-0">
                                    <div className="form-group">
                                        <input
                                            type="tel"
                                            minLength={7}
                                            maxLength={20}
                                            className="form-control-lg cashflow-form-input"
                                            id="your_number"
                                            placeholder={`${shortForm?.phoneLabel} *`}
                                            value={phone}
                                            onChange={e => setPhone(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="col-12 mx-auto pe-md-0">
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            className="form-control-lg cashflow-form-input"
                                            id="your_email"
                                            placeholder={`${shortForm?.emailLabel} *`}
                                            value={email}
                                            required
                                            onChange={e => setEmail(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="col-12 mx-auto pe-md-0">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control-lg cashflow-form-input"
                                            id="company"
                                            placeholder={`${shortForm?.companyLabel} *`}
                                            value={company}
                                            required
                                            onChange={e => setCompany(e.target.value)}
                                        />
                                    </div>
                                </div>
                                
                            </div>

                            <div className="text-center text-md-start mx-auto pt-4 pt-md-0">
                                <div className="text-danger">
                                    {error}
                                </div> 
                                <button type="submit" className="default-btn font-medium">
                                    {shortForm?.submitButtonText}
                                    <span></span>
                                </button>
                            </div>
                        </form>
                        }

                    </div>
                    
                </div>
            </div>
        </section>
    )
}

export default ShortForm