import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/global/Layout";
import Above from "../components/campaigns/cashflow/Above";
import AboveSecond from "../components/campaigns/cashflow/AboveSecond";
import Criteria from "../components/campaigns/cashflow/Criteria";
import HowGetStarted from "../components/campaigns/cashflow/HowGetStarted";
import GetInTouch from "../components/global/forms/GetInTouch";
import { useLocale } from "../utils/use-country-location";
import { parseSeoFromQuery } from "../components/global/SEO";

export default function Landing(props) {
  const locale = useLocale();
  // Not ordered as in CMS! =/
  const data = props?.data?.markdownRemark?.frontmatter;

  return (
    <Layout {...parseSeoFromQuery(props.data)}>
      <Above {...data?.banner} />
      {data?.facts && <AboveSecond {...data?.facts} />}
      {data?.getStarted && <HowGetStarted {...data?.getStarted} />}
      {data?.criteria && <Criteria {...data?.criteria} />}
      {data?.getInTouch && <GetInTouch {...data?.getInTouch} />}
    </Layout>
  );
}

export const query = graphql`
  query ($page: String, $locale: String, $slug: String) {
    markdownRemark(
      frontmatter: {
        page: { eq: $page }
        locale: { eq: $locale }
        slug: { eq: $slug }
      }
    ) {
      frontmatter {
        ...seo
        ...getInTouch
        banner {
          whiteTitle
          primaryTitle
          bannerText
          shortForm {
            title
            onSubmittedMessage
            submitButtonText
            hubspotLink
            nameLabel
            companyLabel
            phoneLabel
            emailLabel
            emailAlert
          }
        }
        facts {
          factOneTitle
          factTwoTitle
          factThreeTitle
          factFourTitle
          factOneText
          factTwoText
          factThreeText
          factFourText
        }
        getStarted {
          title
          textOne
          textTwo
          textThree
          textFour
        }
        criteria {
          title
          description
          criteriaList {
            title
          }
        }

        date
        slug
      }
      html
    }
  }
`;
