import React from 'react'
import ReactWOW from 'react-wow'

const LandingHowToGetStarted = ({ 
    title,
    textOne,
    textTwo,
    textThree,
    textFour 
  }) => {
    return (
        <section className="cashflow-howgetstarted-area">
            <div className="container pb-3">
                <div className="row">
                    <div className="col-12 mx-auto">
                        <ReactWOW delay='.1s' animation='fadeInLeft'>
                            <h1 className='display-6 font-medium pb-3 pb-sm-6 text-center text-md-start'>{title}</h1>
                        </ReactWOW>
                        
                        <div className="row m-auto">
                        <ReactWOW delay='.1s' animation='fadeInLeft'>
                            <div className="col-sm-6 col-lg-3 ">
                                <div class="slideblack_text pe-lg-4">
                                <h2 class="slideblack_heading mb-0">01</h2>
                                <p className="pe-lg-5">{textOne}</p>
                                </div>
                            </div>

                            <div className="col-sm-6 col-lg-3 ">
                                <div class="slideblack_text pe-lg-4">
                                <h2 class="slideblack_heading mb-0">02</h2>
                                <p className="pe-lg-5">{textTwo}</p>
                                </div>
                            </div>

                            <div className="col-sm-6 col-lg-3 pt-sm-5 pt-lg-0">
                                <div class="slideblack_text pe-lg-4">
                                <h2 class="slideblack_heading mb-0">03</h2>
                                <p className="pe-lg-5">{textThree}</p>
                                </div>
                            </div>

                            <div className="col-sm-6 col-lg-3 pt-3 pt-sm-5 pt-lg-0">
                                <div class="slideblue2_text pe-lg-4">
                                    <h2 class="slideblack_heading mb-0 text-secondary">04</h2>
                                    <p className="">{textFour}</p>
                                </div>
                            </div>
                            </ReactWOW>
                        </div>
                        
                    </div>
                </div>                
            </div>
        </section>
    )
}

export default LandingHowToGetStarted 