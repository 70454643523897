import React from 'react'
import ReactWOW from 'react-wow'

const LandingCriteria = ({ 
    title,
    description,
    criteriaList
  }) => {

    return (
        <section className="cashflow-area pb-6 pb-md-8 pt-0">
            <div className="container">
                <div className="row">
                    
                    <div className="col-12 col-md-6 text-center text-md-start pt-2 pt-md-4 px-2">
                        <ReactWOW delay='.1s' animation='fadeInLeft'>
                            <h1 className='display-4 pe-lg-5'>{title}</h1>
                            <p className='contactus-text pt-4 pt-md-3 pe-lg-5 pb-3 pb-md-0'>{description}</p>
                        </ReactWOW>
                    </div>

                    <div className="col-12 col-md-6 pt-4">
                        <div className='cashflow-criteria'>
                            {criteriaList?.map(criterion => (
                                <p className='cashflow-criteria-list'>{criterion.title}</p>
                            ))}
                        </div>
                    </div>
                </div>                
            </div>
        </section>
    )
}

export default LandingCriteria 