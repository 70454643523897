import React from "react"

  const LandingAboveSecond = ({ 
    factOneTitle, 
    factOneText, 
    factTwoTitle, 
    factTwoText, 
    factThreeTitle, 
    factThreeText, 
    factFourTitle, 
    factFourText, 
  }) => {

  return (
    <div className="container-fluid bg-darkgray text-white">
      <div className="container">
        <div className="row cashflow-list">
          <div className="col-12 col-sm-6 col-lg-3 pe-lg-4">
            <h3 className="display-7">{factOneTitle}</h3>
            <p className="text-white">{factOneText}</p>
          </div>

          <div className="cashflow-second-column-gap col-12 col-sm-6 col-lg-3 pe-lg-4 ">
            <h3 className="display-7 ">{factTwoTitle}</h3>
            <p className="text-white">{factTwoText}</p>
          </div>

          <div className="col-12 col-sm-6 col-lg-3 pe-lg-4 pt-0 pt-sm-5 pt-lg-0">
            <h3 className="display-7">{factThreeTitle}</h3>
            <p className="text-white">{factThreeText}</p>
          </div>

          <div className="cashflow-second-column-gap col-12 col-sm-6 col-lg-3 pe-lg-4 pt-0 pt-sm-5 pt-lg-0">
            <h3 className="display-7">{factFourTitle}</h3>
            <p className="text-white">{factFourText}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LandingAboveSecond
