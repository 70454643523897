import React from "react"
import ReactWOW from "react-wow"
import ShortForm from "./ShortForm"

const LandingAbove = ({ 
    whiteTitle, 
    primaryTitle, 
    bannerText,
    shortForm
  }) => {
  return (
    <div className="cashflow-container">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-md-6 cashflow-banner">
            <div className="text-white ">
              <ReactWOW delay=".1s" animation="fadeInLeft">
                <h1 className="display-4">{whiteTitle} 
                  <span className="text-primary">
                    <br />
                    {primaryTitle} 
                  </span>
                </h1>
                <p className="text-white pt-3 pe-md-5 pe-lg-0"> {bannerText} </p>
              </ReactWOW>
            </div>
          </div>
          
          <div className="col-12 col-md-6 cashflow-form pe-md-0">
            <div className="text-white">
              <ShortForm shortForm={shortForm} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LandingAbove
